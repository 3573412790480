
import React,{useEffect} from "react";
import '../Styles/Privacy.css'
import { useTranslation } from "react-i18next";


function FirstPage() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });


  return (
    <div  className="privacy-content" style={{width:"70%", margin:"100px auto 0 auto", lineHeight:"1.8"}}>
    <h1>{t("terms.title")}</h1>
    <p>{t("terms.content")}</p>
    <h2>{t("terms.title02")}</h2>
    <p>{t("terms.content02")}</p>
    <h2>{t("terms.title03")}</h2>
    <p>{t("terms.content03")}</p>
    <h2>{t("terms.title04")}</h2>
    <p>{t("terms.content04")}</p>
    <h2>{t("terms.title05")}</h2>
    <p>{t("terms.content05")}</p>
  </div>
  )
}

export default FirstPage;
