
import honeyRush from "../Assets/honey.webp";
import cashCommand from "../Assets/Cash-of-Command-e1658401060494.webp"
import reactoon from "../Assets/reactzoon.webp";
import catscash from "../Assets/catscash.webp";
import minibaccarat from "../Assets/baccarat.webp";
import deflepar from "../Assets/deffaparrat.webp";
import easteregg from "../Assets/easter-eggs-slot-playn-go.webp";
import iceJoker from "../Assets/iceJoker.webp";
import riseofmerlin from "../Assets/riseofMerlin.webp";
import lagacy from "../Assets/59995c_1da1a8b6670245fb8f80b37a3ba7367e~mv2.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";



export const data = [
  //1
  {
    id: 1,
    logo: "honeyRush",
    stars: [
      <FontAwesomeIcon icon={faStar} />,
      <FontAwesomeIcon icon={faStar} />,
      <FontAwesomeIcon icon={faStar} />,
      <FontAwesomeIcon icon={faStar} />,
      <FontAwesomeIcon icon={faStar} />,
    ],
    address: "Honey Rush 100",
    logo: honeyRush,
    score: "9.8",
    link: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=honeyrush100&lang=en_GB&practice=1&channel=desktop&demo=2",
  },

  //2
  {
    id: 2,
    logo: cashCommand,
    address: "Cash of Command",
    stars: [
      <FontAwesomeIcon icon={faStar} />,
      <FontAwesomeIcon icon={faStar} />,
      <FontAwesomeIcon icon={faStar} />,
      <FontAwesomeIcon icon={faStar} />,
      <FontAwesomeIcon icon={faStar} />,
    ],
    score: "9.6",
    link: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=cashofcommand&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  // 3
  {
    id: 3,
    logo: reactoon,
    address: "Reactoons Two",
    stars: [
      <FontAwesomeIcon icon={faStar} />,
      <FontAwesomeIcon icon={faStar} />,
      <FontAwesomeIcon icon={faStar} />,
      <FontAwesomeIcon icon={faStar} />,
      <FontAwesomeIcon icon={faStar} />,
    ],
    score: "9.5",
    link: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=reactoonztwo&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  //4
  {
    id: 4,
    logo: catscash,
    address: "Cats Cash",
    stars: [
      <FontAwesomeIcon icon={faStar} />,
      <FontAwesomeIcon icon={faStar} />,
      <FontAwesomeIcon icon={faStar} />,
      <FontAwesomeIcon icon={faStar} />,
    ],
    score: "9.5",
    link: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=catsandcash&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  //5
  {
    id: 5,
    logo: minibaccarat,
    address: "Mini Baccarat",
    stars: [
      <FontAwesomeIcon icon={faStar} />,
      <FontAwesomeIcon icon={faStar} />,
      <FontAwesomeIcon icon={faStar} />,
      <FontAwesomeIcon icon={faStar} />,
    ],
    score: "8.8",
    link: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=minibaccarat&lang=en_GB&practice=1&channel=desktop&demo=2",
  },

  //6
  {
    id: 6,
    logo: deflepar,
    stars: [
      <FontAwesomeIcon icon={faStar} />,
      <FontAwesomeIcon icon={faStar} />,
      <FontAwesomeIcon icon={faStar} />,
    ],
    address: "Def Leppard Hysteria",
    score: "8.9",
    link: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=defleppard&lang=en_GB&practice=1&channel=desktop&demo=2",
  },

  //7
  {
    id: 7,
    logo: lagacy,
    stars: [
      <FontAwesomeIcon icon={faStar} />,
      <FontAwesomeIcon icon={faStar} />,
      <FontAwesomeIcon icon={faStar} />,
    ],
    address: "Legacy of Dynasties",
    score: "8.4",
    link: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=legacyofdynasties&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  //8
  {
    id: 8,
    logo: easteregg,
    stars: [
      <FontAwesomeIcon icon={faStar} />,
      <FontAwesomeIcon icon={faStar} />,
    ],
    address: "Easter Egg",
    score: "8.9",
    link: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=eastereggs&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  //9
  {
    id: 9,

    logo: iceJoker,
    stars: [
      <FontAwesomeIcon icon={faStar} />,
      <FontAwesomeIcon icon={faStar} />,
      <FontAwesomeIcon icon={faStar} />,
    ],
    address: "Ice Joker",
    score: "8.1",
    link: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=icejoker&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  //10
  {
    id: 10,
    logo: riseofmerlin,
    stars: [
      <FontAwesomeIcon icon={faStar} />,
      <FontAwesomeIcon icon={faStar} />,
      <FontAwesomeIcon icon={faStar} />,
    ],
    address: "Rise of Merlin",
    score: "7.9",
    link: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=riseofmerlin&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  
];


