import React from "react";
import "../Styles/Stats.css";
import icon1 from "../Assets/award-svgrepo-com.webp";
import icon2 from "../Assets/clerk-with-tie-svgrepo-com.webp";
import icon3 from "../Assets/users-people-svgrepo-com.webp";
import { useTranslation } from "react-i18next";


const StatsItem = ({ image, number, item }) => {
  return (
    <div className="stats-content">
      <div className="stats-img">
        <img src={image} alt="stats-icon" />
      </div>
      <span>{number}</span>
      <h3>{item}</h3>
    </div>
  );
};

export default function Stats() {
  const { t } = useTranslation();

  return (
    <div className="stats-section">
      <StatsItem image={icon3} number={975} item={t("stats.item1")} />
      <StatsItem image={icon2} number={10} item={t("stats.item2")} />
      <StatsItem image={icon1} number={170} item={t("stats.item3")} />
    </div>
  );
}
