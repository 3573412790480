import React, {useEffect} from 'react'
import Heros from '../Components/Heros'
import About from '../Components/About'
import Games from '../Components/Games'
import Stats from '../Components/Stats'
import Contact from '../Components/Contact'


function Home() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });
  return (
    < >

      <Heros />
      <Games/>
  
  
      <About/>
      <Stats/>
<Contact/>
    </>
  )
}

export default Home
